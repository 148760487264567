export const passwordValidatorErrorMessages = {
  PasswordBadLength: 'Response.PasswordBadLength',
  PasswordComplexity: 'Response.PasswordComplexity',
  PasswordContainsUsernameError: 'Response.PasswordContainsUsernameError'
};

export const weakPasswords = [
  'roblox123',
  'password',
  'password1',
  'password12',
  'password123',
  'trustno1',
  'iloveyou',
  'princess',
  'abcd1234',
  'qwertyui',
  'qwerty',
  'football',
  'baseball',
  'michael',
  'jennifer',
  'michelle',
  'babygirl',
  'superman',
  '12345678',
  '123456789',
  '1234567890',
  '123123123',
  '69696969',
  '11111111',
  '22222222',
  '33333333',
  '44444444',
  '55555555',
  '66666666',
  '77777777',
  '88888888',
  '99999999',
  '00000000'
];
